import { RootState } from '@/store/store';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { closePaymentModal } from '../../../Plan/PlanApiSlice/PlanSlice';
import PaymentError from '../PaymentError';
import PaymentForm from '../PaymentForm';
import PaymentSuccess from '../PaymentSucess';

export default function PaymentModal() {
  const dispatch = useDispatch();
  const { showPaymentModal, selectedPlan } = useSelector((state: RootState) => state.PlanSlice);

  const [planInfo, setPlanInfo] = useState({
    planType: '',
    planPrice: '',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    cardholderName: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentView, setCurrentView] = useState<'form' | 'success' | 'error'>('form');

  useEffect(() => {
    if (showPaymentModal && selectedPlan) {
      const planPrice = selectedPlan.planType === 1 ? selectedPlan.annualPrice : selectedPlan.monthlyPrice;

      setPlanInfo({
        planType: selectedPlan.planType === 1 ? 'Annual' : 'Monthly',
        planPrice: planPrice.toString(),
        cardNumber: '',
        expiryDate: '',
        cvv: '',
        cardholderName: '',
      });
    }
  }, [showPaymentModal, selectedPlan]);

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let formattedInput = value;
    if (name === 'cardNumber') {
      formattedInput = value
        .replace(/\s/g, '')
        .substring(0, 16)
        .replace(/(\d{4})(?=\d)/g, '$1 ');
    } else if (name === 'expiryDate') {
      formattedInput = value
        .replace(/[^0-9]/g, '')
        .substring(0, 4)
        .replace(/(\d{2})(\d{1,2})/, '$1/$2');
    } else if (name === 'cvv') {
      formattedInput = value.substring(0, 3);
    }
    setPlanInfo((prevState) => ({ ...prevState, [name]: formattedInput }));
  }, []);

  const validateForm = useCallback(() => {
    const { cardNumber, expiryDate, cvv, cardholderName } = planInfo;
    return cardNumber.trim() && expiryDate.trim() && cvv.trim() && cardholderName.trim();
  }, [planInfo]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!validateForm()) {
        alert('Por favor, complete todos los campos.');
        return;
      }
      setIsSubmitting(true);
      setTimeout(() => {
        setIsSubmitting(false);
        const success = Math.random() > 0.5;
        setCurrentView(success ? 'success' : 'error');
      }, 2000);
    },
    [validateForm]
  );

  return (
    <Modal show={showPaymentModal} onHide={() => dispatch(closePaymentModal())} centered>
      <Modal.Header closeButton>
        <Modal.Title>{currentView === 'form' ? 'Método de Pago' : currentView === 'success' ? 'Pago Exitoso' : 'Error en Pago'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentView === 'form' && <PaymentForm planInfo={planInfo} isSubmitting={isSubmitting} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />}
        {currentView === 'success' && <PaymentSuccess />}
        {currentView === 'error' && <PaymentError />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => dispatch(closePaymentModal())} disabled={isSubmitting}>
          Cancelar
        </Button>
        {currentView !== 'form' && (
          <Button variant="primary" onClick={() => setCurrentView('form')}>
            Volver
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
