import { Route, Routes } from 'react-router-dom'
import About from '../About/pages/About'
import LoginPage from '../Auth/Pages/LoginPage'
import PrivacyPolicy from '../Common/Pages/PrivacyPolicies'
import TermsAndConditions from '../Common/Pages/TermsAndConditions'
import GetApp from '../Home/Pages/GetApp'
import Home from '../Home/Pages/Home'
import BlogDetailsPage from '../pages/BlogDetailsPage'
import BlogPage from '../pages/BlogPage'
import BreederDetailsPage from '../pages/BreederDetailsPage'
import BreederPage from '../pages/BreederPage'
import ContactPage from '../pages/ContactPage'
import DogList from '../pages/DogList'
import HometwoPage from '../pages/HometwoPage'
import ShopDetailsPage from '../pages/ShopDetailsPage'
import ShopPages from '../pages/ShopPages'
import DeleteAccount from '@/Common/Pages/DeleteAccount'

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/get-app" element={<GetApp />} />
      <Route path="/about" element={<About />} />
      <Route path="/delete-account" element={<DeleteAccount />} />
      <Route path="/doglist" element={<DogList />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/contacts" element={<ContactPage />} />
      <Route path="/blogs" element={<BlogPage />} />
      <Route path="/blog-details" element={<BlogDetailsPage />} />
      <Route path="/shop" element={<ShopPages />} />
      <Route path="/shop-details" element={<ShopDetailsPage />} />
      <Route path="/breeder" element={<BreederPage />} />
      <Route path="/breeder-details" element={<BreederDetailsPage />} />
      <Route path="/home-two" element={<HometwoPage />} />
    </Routes>
  )
}
