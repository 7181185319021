import HomeStatics from '@/Home/Representations/HomeStatics'
import { useEffect, useState } from 'react'
import counter_bg from '../../../img/bg/counter_bg.jpg'
import { getStaticsHome } from '../../Services/homeServices'

export default function Contador() {
  const [statistics, setStatistics] = useState<HomeStatics | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const stats = await getStaticsHome()
        console.log('Statistics llamasdo api', stats)
        setStatistics(stats)
      } catch (error) {
        console.error('Error fetching statistics:', error)
      }
    }
    fetchData()
  }, [])

  return (
    <section className="counter-area counter-bg" style={{ backgroundImage: `url(${counter_bg})` }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="counter-title text-center mb-65">
              <h6 className="sub-title">¿Por qué elegirnos?</h6>
              <h2 className="title">Servicio para mascotas</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="counter-item">
              <h2 className="count">{statistics ? <span className="odometer">{statistics.TotalUsers}+</span> : <span className="skeleton"></span>}</h2>
              <p>Dueños felices por su mascota</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="counter-item">
              <h2 className="count">{statistics ? <span className="odometer">{statistics.EstablishmentRegistered}+</span> : <span className="skeleton"></span>}</h2>
              <p>Veterinarios disponibles</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="counter-item">
              <h2 className="count">{statistics ? <span className="odometer">{statistics.EstablishmentType2}k</span> : <span className="skeleton"></span>}</h2>
              <p>Servicios registrados</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="counter-item">
              <h2 className="count">{statistics ? <span className="odometer">{statistics.TotalPets}+</span> : <span className="skeleton"></span>}</h2>
              <p>Servicios para tu mascota</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
