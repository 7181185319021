import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Footer from './Common/Components/Footer';
import Header from './Common/Components/Header';
import AppRoutes from './Routes';
import ScrollToTop from './ScrollToTopRoute';
import store, { AppDispatch } from './store/store';
import { initializeAuth } from './Auth/AuthApiSlice/AuthSlice';
import { useDispatch } from 'react-redux';

function AppContent() {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeAuth());
  }, [dispatch]);

  return (
    <div className="app">
      <ScrollToTop />
      <Header />
      <AppRoutes />
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppContent />
      </Router>
    </Provider>
  );
}

export default App;
