import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import { fetchRaces } from '../../Services/PetServices'

interface PetFormProps {
  petName: string
  setPetName: (value: string) => void
  petYear: string
  setPetYear: (value: string) => void
  petBreed: string
  setPetBreed: (value: string) => void
  petType: string
  setPetType: (value: string) => void
  petSize: string
  setPetSize: (value: string) => void
}

export default function PetForm({ petName, setPetName, petYear, setPetYear, petBreed, setPetBreed, petType, setPetType, petSize, setPetSize }: PetFormProps) {
  const [breeds, setBreeds] = useState<{ label: string; value: string }[]>([])
  const [loadingBreeds, setLoadingBreeds] = useState(false)

  useEffect(() => {
    if (petType && (petSize || petType === 'cat')) {
      const typeValue = petType === 'dog' ? 1 : 2
      const sizeValue = petType === 'dog' ? (petSize === 'small' ? 'P' : petSize === 'medium' ? 'M' : 'G') : 'S'

      setLoadingBreeds(true)
      fetchRaces(typeValue, sizeValue)
        .then((data) => {
          setBreeds(data)
          setLoadingBreeds(false)
        })
        .catch((error) => {
          console.error('Error fetching breeds:', error)
          setLoadingBreeds(false)
        })
    }
  }, [petType, petSize])

  useEffect(() => {
    setPetBreed('')
  }, [petType, petSize])

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>Nombre de la mascota</Form.Label>
        <Form.Control type="text" value={petName} onChange={(e) => setPetName(e.target.value)} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Fecha de nacimiento</Form.Label>
        <Form.Control type="date" value={petYear} onChange={(e) => setPetYear(e.target.value)} />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Tipo de mascota</Form.Label>
        <Form.Select
          value={petType}
          onChange={(e) => {
            setPetType(e.target.value)
          }}
        >
          <option value="">Seleccionar</option>
          <option value="dog">Perro</option>
          <option value="cat">Gato</option>
        </Form.Select>
      </Form.Group>

      {petType === 'dog' && (
        <Form.Group className="mb-3">
          <Form.Label>Tamaño del perro</Form.Label>
          <Form.Control
            as="select"
            value={petSize}
            onChange={(e) => {
              setPetSize(e.target.value)
            }}
          >
            <option value="">Seleccionar</option>
            <option value="small">Pequeño</option>
            <option value="medium">Mediano</option>
            <option value="large">Grande</option>
          </Form.Control>
        </Form.Group>
      )}

      {(petType === 'cat' || (petType === 'dog' && petSize)) && (
        <Form.Group className="mb-3">
          <Form.Label>Raza</Form.Label>
          <Select
            isLoading={loadingBreeds}
            options={breeds}
            value={breeds.find((breed) => breed.value === petBreed)}
            onChange={(selectedOption) => setPetBreed(selectedOption ? selectedOption.value : '')}
            placeholder="Selecciona la raza"
            isClearable
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />
        </Form.Group>
      )}
    </Form>
  )
}
