import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import ReactDOM from 'react-dom/client';
import App from './App';

// Add a type guard to handle the null case
const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<App />);

  // jQuery preloader logic
  $(document).ready(function () {
    $('#preloader').delay(0).fadeOut();
  });
} else {
  console.error('Root element not found');
}
